// src/pages/website/DownloadSecuritiesJSON.jsx
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchData } from '../../api'; // Убедитесь, что путь правильный

const DownloadSecuritiesJSON = () => {
    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [inputPassword, setInputPassword] = useState('');

    // Жестко закодированный пароль
    const HARDCODED_PASSWORD = 'Bcf2023'; // Замените на ваш пароль

    useEffect(() => {
        if (!authenticated) return; // Не выполнять запрос, если не авторизован

        const params = new URLSearchParams(location.search);
        const date = params.get('date');
        const pid = params.get('pid');
        const rid = params.get('rid');

        console.log('Download Request Params:', { date, pid, rid });

        // Валидация формата даты
        const isValidDate = (dateString) => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
        };

        if (date && !isValidDate(date)) {
            setError('Некорректный формат даты.');
            setTimeout(() => history.push('/securities'), 3000);
            return;
        }

        // Функция для фильтрации данных
        const filterData = (data) => {
            let filtered = data;

            if (date) {
                const targetDate = new Date(date);
                targetDate.setHours(0, 0, 0, 0);
                const nextDay = new Date(targetDate);
                nextDay.setDate(targetDate.getDate() + 1);

                filtered = filtered.filter(item => {
                    const itemDate = new Date(item['Date']);
                    return itemDate >= targetDate && itemDate < nextDay;
                });
            }

            if (pid) {
                filtered = filtered.filter(item => item['PID'].toString() === pid);
            }

            if (rid) {
                filtered = filtered.filter(item => item['RID'].toString() === rid);
            }

            return filtered;
        };

        // Функция для скачивания JSON
        const downloadJSON = (data, filters) => {
            if (Object.keys(filters).length && data.length === 0) {
                setError('Нет данных для скачивания с заданными фильтрами.');
                console.warn('No data found for the provided filters:', filters);
                setTimeout(() => history.push('/securities'), 3000);
                return;
            }

            const jsonString = JSON.stringify(data, null, 2);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            // Формирование имени файла с учётом фильтров
            let fileName = 'securities';
            const filterParts = [];
            if (filters.date) filterParts.push(`date=${filters.date}`);
            if (filters.pid) filterParts.push(`pid=${filters.pid}`);
            if (filters.rid) filterParts.push(`rid=${filters.rid}`);

            if (filterParts.length) {
                fileName += `_${filterParts.join('_')}`;
            }
            fileName += '.json';

            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            console.log('JSON Downloaded:', fileName);
            history.push('/securities');
        };

        // Получение данных и их обработка
        const getData = async () => {
            setIsLoading(true);
            try {
                const data = await fetchData();
                const filteredData = filterData(data);
                console.log('Filtered Data Count:', filteredData.length);
                downloadJSON(filteredData, { date, pid, rid });
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Ошибка при получении данных.');
                setTimeout(() => history.push('/securities'), 3000);
            } finally {
                setIsLoading(false);
            }
        };

        getData();
    }, [authenticated, location.search, history]);

    // Обработчик отправки формы аутентификации
    const handleAuthSubmit = (e) => {
        e.preventDefault();
        if (inputPassword === HARDCODED_PASSWORD) {
            setAuthenticated(true);
            setError(null);
        } else {
            setError('Неверный пароль.');
        }
    };

    if (!authenticated) {
        return (
            <div className="auth-container" style={styles.authContainer}>
                <h2>Введите пароль для доступа к данным</h2>
                <form onSubmit={handleAuthSubmit} style={styles.form}>
                    <input
                        type="password"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                        placeholder="Пароль"
                        required
                        style={styles.input}
                    />
                    <button type="submit" style={styles.button}>Войти</button>
                </form>
                {error && <div className="error" style={styles.error}>{error}</div>}
            </div>
        );
    }

    if (error) {
        return <div className="error" style={styles.error}>Ошибка: {error}. Перенаправление...</div>;
    }

    if (isLoading) {
        return <div className="loading" style={styles.loading}>Скачивание данных...</div>;
    }

    return <div className="success" style={styles.success}>Данные успешно загружены.</div>;
};

const styles = {
    authContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '100px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        gap: '10px'
    },
    input: {
        padding: '10px',
        fontSize: '16px'
    },
    button: {
        padding: '10px',
        fontSize: '16px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    error: {
        color: 'red',
        marginTop: '10px'
    },
    loading: {
        textAlign: 'center',
        marginTop: '50px',
        fontSize: '18px'
    },
    success: {
        textAlign: 'center',
        marginTop: '50px',
        fontSize: '18px'
    }
};

export default DownloadSecuritiesJSON;

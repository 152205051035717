import React, { useEffect } from "react";
import notFound from "../../assets/images/background-404.jpg";
import logo from "../../assets/images/logo.png";
import "../../assets/styles/style.css";
import telegram from "../../assets/images/telegram.png";
import instagram from "../../assets/images/instagram.png";
import twitter from "../../assets/images/twitter.png";
import google from "../../assets/images/google.png";
import linkedin from "../../assets/images/linkedin.png";
import { Link } from "react-router-dom";
import HeaderConferences from "../../components/website/HeaderConferences";
function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <HeaderConferences />

      <main>
        <section>
          <div className="container">
            <h2 className="error-title">
              The page you requested could not be found...
            </h2>
            <div className="error-page">
              <img src={notFound} />
            </div>
            <div className="error-btn-container">
              <button className="btn-content">
                <Link to="/" className="btn-content">
                  {" "}
                  Return to main
                </Link>
              </button>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-wrapper">
          <ul className="social-container">
            <li>
              <img src={logo} />
            </li>
            <li className="social">
              <img src={telegram} />

              <img src={instagram} />
              <img src={twitter} />
              <img src={google} />
              <img src={linkedin} />
            </li>
          </ul>
          <ul className="departaments">
            <ul>
              <li>
                Marketing Department<span> +420 228 227 162</span>
              </li>
              <li>
                Production Department<span> +420 228 227 163</span>
              </li>
              <li>
                General<span> +420 214 214 720</span>
              </li>
            </ul>
            <ul>
              <li>
                Operations Department<span> +420 228 227 164</span>
              </li>
              <li>
                Support<span> +420 776 543 575</span>
              </li>
              <li>
                <span>general@bcf-events.com</span>
              </li>
            </ul>
          </ul>
          <ul className="links">
            <a href="#">Data policy</a>
            <a href="#">Terms & Conditions</a>
          </ul>
        </div>
        <div className="laptop-footer">
          <div className="flex-laptop">
            <div className="laptop-logo">
              <img src={logo} className="laptop-logo-img" />
            </div>
            <li className="social">
              <img
                src={require("../../assets/images/telegram-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/instagram-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/twitter-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/google-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/linkedin-mobile.png")}
                alt=""
              />
            </li>
          </div>
          <span className="line-footer-laptop"></span>
          <div>
            <ul className="laptop-flex">
              <li>
                <p className="title-laptop">Marketing Department</p>
                <p className="contact-laptop">+420 228 227 162</p>
                <p className="title-laptop">Production Department</p>
                <p className="contact-laptop">+420 228 227 163</p>
                <p className="title-laptop">General</p>
                <p className="contact-laptop">+420 214 214 720</p>
              </li>
              <li>
                <p className="title-laptop">Operations Department</p>
                <p className="contact-laptop">+420 228 227 164</p>
                <p className="title-laptop">Support</p>
                <p className="contact-laptop">+420 776 543 575</p>
              </li>
              <li>
                <p className="title-laptop">Terms & Conditions</p>
                <p className="title-laptop">Data policy</p>
              </li>
            </ul>
            <p className="copyright-laptop">
              Copyright 2023 © All right reserved
            </p>
          </div>
        </div>
        <div className="footer-mobile">
          <div className="footer-logo">
            <img src={logo} />
          </div>
          <span className="line-footer"></span>
          <div>
            <ul className="footer-list">
              <li>
                Marketing Department<span> +420 228 227 162</span>
              </li>
              <li>
                Production Department<span> +420 228 227 163</span>
              </li>
              <li>
                General<span> +420 214 214 720</span>
              </li>
              <li>
                Operations Department<span> +420 228 227 164</span>
              </li>
              <li>
                Support<span> +420 776 543 575</span>
              </li>
              <li>
                <span>general@bcf-events.com</span>
              </li>
            </ul>
          </div>
          <ul className="links-mobile">
            <a href="#">Data policy</a>
            <a href="#">Terms & Conditions</a>
          </ul>
          <span className="line-footer"></span>
          <ul className="social-mobile-footer">
          <li className="social">
              <img
                src={require("../../assets/images/telegram-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/instagram-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/twitter-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/google-mobile.png")}
                alt=""
              />
              <img
                src={require("../../assets/images/linkedin-mobile.png")}
                alt=""
              />
            </li>
          </ul>
          <p className="copyright-mobile">
            Copyright 2023 © All right reserved
          </p>
        </div>
      </footer>
    </main>
  );
}

export default NotFound;

import React, { useState, useContext } from 'react';
import AuthContext from "../context/AuthContext";
import HeaderConferences from "../../website/HeaderConferences";
import Breadcrumbs from "../../Breadcrumbs";
import Footer from "../../website/Footer";

function LoginPage({ conference }) {
    const [email, setEmail] = useState('');
    const { login } = useContext(AuthContext);

    const handleLogin = (event) => {
        event.preventDefault();
        // Передаем email и название конференции
        login({ email, conferenceName: conference.eventName });
    };


    return (
        <div>
            <HeaderConferences />
            <main>
                <section>
                    <div className="background-block unsubscribe-block">
                        <img className="unsubscribe-image" style={{
                            backgroundImage: `url(${conference.eventImage})`,
                            backgroundSize: 'cover'
                        }} />
                        <div className="container">
                            <div className="breadcrumbs-about">
                                <ul>
                                    <a href="/">
                                        <li className="andThisStyle title-breadcrumbs unsubscribe-breadcrumbs">MAIN</li>
                                    </a>
                                    <li className="thisStyle description-breadcrumbs unsubscribe-breadcrumbs">
                                        LOGIN PAGE
                                    </li>
                                </ul>
                                <Breadcrumbs />
                            </div>
                            <div className="background-single"></div>
                            <div className="centered-block">
                                <h2 className="unsubscribe-title">Login Page</h2>
                                <form className="unsubscribe-form" onSubmit={handleLogin}>
                                    <label htmlFor="email">Enter your email (required)*:</label>
                                    <input
                                        className="unsubscribe-email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <br/>
                                    <button type="submit" className="unsubscribe-btn">Confirm email</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default LoginPage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'auto';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode off>
    <App/>
  </React.StrictMode>
);

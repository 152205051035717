import axios from 'axios';

// Axios middleware configuration
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

export default axios;

import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom";
// Import the main component


import HTMLFlipBook from 'react-pageflip';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import Breadcrumbs from "../../components/Breadcrumbs";

function About() {
  const [heightPdf, setHeightPdf] = useState(500);
  const [widthPdf, setWidthPdf] = useState(500);

  useEffect(() => {
   const sizePdf = () => {
      const windowWidth = window.innerWidth;

    if (windowWidth <= 340) {
        setHeightPdf(270)
        setWidthPdf(270)
     }
     else if (windowWidth <= 360) {
      setHeightPdf(300)
      setWidthPdf(300)
    }
    else if (windowWidth <= 425) {
      setHeightPdf(350)
      setWidthPdf(350)
    }
    else if (windowWidth <= 500) {
      setHeightPdf(400)
      setWidthPdf(400)
    }
    else if (windowWidth <= 1111) {
      setHeightPdf(450)
      setWidthPdf(450)
    }
    else {
      setHeightPdf(500)
      setWidthPdf(500)
    }

  };

  window.addEventListener('resize', sizePdf);
  sizePdf();

  return () => window.removeEventListener('resize', sizePdf);
}, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const book = useRef();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const nextButtonClick = () => {
    book.current.pageFlip().flipNext()
  };

  const prevButtonClick = () => {
    book.current.pageFlip().flipPrev()
  };

  const containerWidth = widthPdf * 2;

  const isMobile = windowWidth <= 1111;

  const containerStyle = isMobile ?
      { height: `${heightPdf}px`, width: `${widthPdf}px` } :
      { border: '1px solid rgba(0, 0, 0, 0.3)', height: `${heightPdf}px`, width: `${containerWidth}px` };
  return (
      <div>
        <Header />
        <main>
          <section>
            <div className="main-container">
              <div className="about-background"></div>
              <div className="background-laptop"></div>
              <div className="background-mobile"></div>
              <div className="background-block">
                <div className="container">
                  <div className="breadcrumbs-about">
                    <ul>
                      <li className="andThisStyle">
                        <Link className="conference-link add-link-breadcrumbs" to="/">
                          MAIN
                        </Link>
                      </li>
                      <li className="thisStyle">ABOUT US</li>
                    </ul>
                    <Breadcrumbs />
                  </div>
                  <div>
                    <div style={{ position: "relative" }}>
                      <h2 className="about-title">ABOUT US</h2>
                      <span
                          className="line-title"
                          style={{ bottom: "-10px" }}
                      ></span>
                      <span
                          className="line-mobile"
                          style={{ bottom: "-10px" }}
                      ></span>
                    </div>
                    <p className="about-description">
                      Business Conference Facilitation is a professional organizer
                      of high-level B2B events. We produce, conduct and coordinate
                      conferences, summits and forums in major business industries
                      of emerging Global Market.
                      <br className="br-about" />
                      <br className="br-about" />
                      <p>Our international conferences aim is to provide our
                      participants with a positive impact and help them achieve
                      efficiency in their daily responsibilities while overcoming
                        any possible challenges.</p>
                    </p>
                  </div>
                  {/*<div className="bottom-arrow"></div>*/}
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="container-our-vision">
                <div className="vision-first">
                  <div style={{ position: "relative" }}>
                    <h3 className="title-vision">Our vision</h3>
                    <p className="description-vision">
                      We aim to create long-lasting relationships with
                      stakeholders of our events through consistency and passion
                      for the work that we do.
                    </p>
                  </div>
                </div>
                <div className="vision-second">
                  <img
                    src={require("../../assets/images/our-vision.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="container-our-vision mobile-our-mission">
                <div className="vision-third">
                  <img
                      src={require("../../assets/images/our-mission.png")}
                      alt=""
                  />
                </div>
                <div className="vision-fourth">
                  <div style={{ position: "relative" }}>
                    <h3 className="title-vision">Our mission</h3>
                    <p className="description-vision">
                      To create memorable events that will empower our attendees
                      in their professional career as well as create brand-new
                      perspectives and outlooks within industries by connecting
                      people together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <h2 className="title-about presentation-title">
                Presentation book
              </h2>
              <div className="line-container line-presentation">
                <span className="line-about"></span>
              </div>
              <div className="container container-pdf"
                   style={containerStyle}
              >
                  <HTMLFlipBook
                      size="stretch"
                      width={widthPdf}
                      height={heightPdf}
                      minWidth={250}
                      minHeight={250}
                      autoSize={true}
                      ref={book}
                      mobileScrollSupport={true}
                  >
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/1.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/2.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/3.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/4.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/5.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/6.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/7.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/8.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/9.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/10.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/11.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/12.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/13.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/14.png")}/></div>
                    <div className="demoPage"><img height={heightPdf} width={widthPdf} src={require("../../assets/pdf/15.png")}/></div>
                  </HTMLFlipBook>
                </div>
              <div className="arrows-wrapper-black">
                <div className="container-black-arrows">
                <div className="left-arrow-black" onClick={prevButtonClick}></div>
                <div className="right-arrow-black" onClick={nextButtonClick}></div>
                </div>
              </div>


            {/*<div className="container">*/}
              {/*  <div>*/}

              {/*    <button type="button" onClick={prevButtonClick}>*/}
              {/*      Previous page*/}
              {/*    </button>*/}
              {/*    <button type="button" onClick={nextButtonClick}>*/}
              {/*      Next page*/}
              {/*    </button>*/}

              {/*  </div>*/}
              {/*  <div className="">*/}
              {/*    <div className="left-arrow-black" onClick={prevButtonClick}></div>*/}
              {/*    <div className="right-arrow-black" onClick={nextButtonClick}></div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className="container"
                style={containerStyle}
            >
                  <Viewer defaultScale={scaleValue}  fileUrl="../../assets/bcf-events.pdf"
                  />
              </div>
            </Worker> */}


              {/* <PDFReader /> */}

              <div id="pdf-bottom"></div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
  );
}

export default About;

import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectToGenotoxicity = () => {
    // Здесь вы можете выполнить какую-то логику перед редиректом (если нужно)

    // После логики редиректим пользователя
    return <Redirect to="/single-conference/2nd-annual-genotoxicity-and-nitrosamine-online-conference" />;
};
// ещё раз

export default RedirectToGenotoxicity;

const allowedPromos = [
    { promoCode: "EXTRA10", eventName: "", discountType: "percent", discountValue: 10 },
    { promoCode: "SPEAKER", eventName: "", discountType: "percent", discountValue: 50 },
    { promoCode: "OFFER500", eventName: "INTERNATIONAL HRD SUMMIT", discountType: "fixed", discountValue: 200, ticketName: "Virtual Delegate Pass" },
    { promoCode: "TEBR10", eventName: "INTERNATIONAL HRD SUMMIT", discountType: "percent", discountValue: 10, ticketName: "Virtual Delegate Pass" },
    { promoCode: "RegPlast25", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "percent", discountValue: 40, ticketName: "Basic Package"},
    { promoCode: "RegPlast25", eventName: "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "percent", discountValue: 40, ticketName: "Standard Package"},
    { promoCode: "OFFER999", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 300},
    { promoCode: "TEBR10", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "percent", discountValue: 10},
    { promoCode: "PASTPA", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 600},
    { promoCode: "PA25V", eventName: "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", discountType: "fixed", discountValue: 200},
    { promoCode: "INH24", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 500},
    { promoCode: "200OFF", eventName: "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", discountType: "fixed", discountValue: 200},
    { promoCode: "OFFER25", eventName: "6TH ANNUAL BTEL SUMMIT & EXHIBITION", discountType: "fixed", discountValue: 400, ticketName: "IN-PERSON"},
    { promoCode: "BASIC PACKAGE", eventName: "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES", discountType: "fixed", discountValue: 400, ticketName: "BASIC PACKAGE"},
    { promoCode: "BASIC PACKAGE", eventName: "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES", discountType: "fixed", discountValue: 400, ticketName: "EXECUTIVE PRESENTER PASS"},
    { promoCode: "BTEL25", eventName: "6TH ANNUAL BTEL SUMMIT & EXHIBITION", discountType: "fixed", discountValue: 500, ticketName: "In person"},
    { promoCode: "Auto300", eventName: "FUTURE AUTOMOTIVE MATERIALS SUMMIT", discountType: "fixed", discountValue: 300, ticketName: "IN-PERSON"},
    { promoCode: "Auto300", eventName: "FUTURE AUTOMOTIVE MATERIALS SUMMIT", discountType: "fixed", discountValue: 300, ticketName: "EXECUTIVE PRESENTER PASS"},


];

const getDiscountFromPromo = (promoCode, eventName, ticketName) => {
    const lowercasePromoCode = promoCode.toLowerCase();
    const lowercaseEventName = eventName.toLowerCase();
    const lowercaseTicketName = ticketName.toLowerCase();
    const excludedKeyword = "recording";

    // Исключить билеты, содержащие слово "recording"
    if (lowercaseTicketName.includes(excludedKeyword)) {
        return null;
    }

    const promo = allowedPromos.find(
        (p) => p.promoCode.toLowerCase() === lowercasePromoCode &&
            (p.eventName.toLowerCase() === lowercaseEventName || p.eventName === "") &&
            (!p.ticketName || (Array.isArray(p.ticketName)
                ? p.ticketName.some((name) => name.toLowerCase() === lowercaseTicketName)
                : p.ticketName.toLowerCase() === lowercaseTicketName))
    );

    return promo ? { type: promo.discountType, value: promo.discountValue } : null;
};

// Пример вызова функции
const discount1 = getDiscountFromPromo("PA25V", "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", "Basic Package");
console.log(discount1); // { type: 'fixed', value: 200 }

const discount2 = getDiscountFromPromo("PA25V", "5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE", "Only Recording");
console.log(discount2);

export default getDiscountFromPromo;

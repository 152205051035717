import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import React, { useEffect, useState} from "react";
import AOS from "aos";
import "swiper/css";
import { Link } from "react-router-dom";
import Swiper, { Navigation } from "swiper";
import { getEvents } from "../../api/endpoints/events";
import api from "../../api/endpoints/events.js";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";

const marks = {
  1: <span className="marcs-mobile-style">Jan.</span>,
  2: <span className="marcs-mobile-style">Feb.</span>,
  3: <span className="marcs-mobile-style">Mar.</span>,
  4: <span className="marcs-mobile-style">Apr.</span>,
  5: <span className="marcs-mobile-style">May.</span>,
  6: <span className="marcs-mobile-style">Jun.</span>,
  7: <span className="marcs-mobile-style">Jul.</span>,
  8: <span className="marcs-mobile-style">Aug.</span>,
  9: <span className="marcs-mobile-style">Sep.</span>,
  10: <span className="marcs-mobile-style">Oct.</span>,
  11: <span className="marcs-mobile-style">Nov.</span>,
  12: <span className="marcs-mobile-style">Dec.</span>,
};

const fullMarks = {
  1: <span className="marcs-style">January</span>,
  2: <span className="marcs-style">February</span>,
  3: <span className="marcs-style">March</span>,
  4: <span className="marcs-style">April</span>,
  5: <span className="marcs-style">May</span>,
  6: <span className="marcs-style">June</span>,
  7: <span className="marcs-style">July</span>,
  8: <span className="marcs-style">August</span>,
  9: <span className="marcs-style">September</span>,
  10: <span className="marcs-style">October</span>,
  11: <span className="marcs-style">November</span>,
  12: <span className="marcs-style">December</span>,
};

function Home() {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [allConferences, setAllConferences] = useState([]);
  const [featuredConferences, setFeaturedConferences] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [activeConferences, setActiveConferences] = useState([]);
  const [currentMarks, setCurrentMarks] = useState(marks);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const updateScreenSize = () => {
    setScreenSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const handleStyle = screenSize < 600 ? {
    borderColor: "#1089F1",
    height: "25px",
    width: "24px",
    bottom: "5px",
    backgroundColor: "white",
    zIndex: '1',
    opacity: '1',
    border: '7px solid rgb(16, 137, 241)'
  } : {
    borderColor: "#1089F1",
    height: "44px",
    width: "44px",
    bottom: "0",
    backgroundColor: "white",
    zIndex: '1',
    opacity: '1',
    border: '15px solid rgb(16, 137, 241)',
  }

  useEffect(() => {
    fetchEventData();

    window.scrollTo(0, 0);
  }, []);
  const fetchEventData = () => {
    getEvents()
        .then((eventData) => {
          console.log("Event Data:", eventData); // Используем данные, чтобы показать их в консоли или обработать
          // Populate your UI or perform other actions with eventData
        })
        .catch((error) => {
          console.error("Error retrieving event data:", error);
        });
  };

  useEffect(() => {
    // Initialize Swiper
    const swiper = new Swiper(".swiper-container", {
      modules: [Navigation],
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: ".right-arrow",
        prevEl: ".left-arrow",
      },
    });

    setSwiperInstance(swiper);

    // Clean up Swiper on component unmount
    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, [featuredConferences]);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [swiperInstance, featuredConferences]); // Добавляем swiperInstance


  useEffect(() => {
    AOS.init({
      delay: 1000,
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    const getAllEventsAndAdjustMonth = async () => {
      try {
        const { data } = await api.get("/Event/get-all-events?pageNumber=1&pageSize=100");
        setAllConferences(data.items);
  
        const sortedConferences = data.items.sort((a, b) => {
          const dateA = new Date(a.endDate);
          const dateB = new Date(b.endDate);
          return dateA - dateB;
        });

        const featured = data.items.filter((conference) => conference.isFeatured);
        setFeaturedConferences(featured);
  
        const filteredOnes = sortedConferences.filter((item) => {
          return new Date(item.endDate).getMonth() + 1 === selectedMonth;
        });
  
        setActiveConferences(filteredOnes);
        
        // Check if there are active conferences in the selected month.
        const conferencesInSelectedMonth = sortedConferences.filter((item) => {
          return new Date(item.endDate).getMonth() + 1 === selectedMonth;
        });
  
        // If there are no active conferences in the selected month,
        // find the next available month with conferences.
        if (conferencesInSelectedMonth.length === 0) {
          let nextAvailableMonth = selectedMonth + 1;
          while (nextAvailableMonth <= 12) {
            const conferencesInNextMonth = sortedConferences.filter((item) => {
              return new Date(item.endDate).getMonth() + 1 === nextAvailableMonth;
            });
  
            if (conferencesInNextMonth.length > 0) {
              setSelectedMonth(nextAvailableMonth);
              break;
            }
  
            nextAvailableMonth++;
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    };
  
    getAllEventsAndAdjustMonth();
    // window.scrollTo(0, 0);
  
    // Rest of your code...
  }, [selectedMonth]);
  

  const changeSlider = (e) => {
    setSelectedMonth(e[0]);
    const filteredOnes = allConferences.filter((item) => {
      return new Date(item.endDate).getMonth() + 1 === e[0];
    });

    setActiveConferences(filteredOnes);
  };

  const formateDate = (date) => {
    const { startDate, endDate } = date;
    const formattedDate = new Date(startDate);
    const [startDay, endDay] = [
      formattedDate.getDate(),
      new Date(endDate).getDate(),
    ];

    const month = formattedDate.toLocaleString("en-US", { month: "long" });
    return `${month} ${startDay} - ${endDay}, ${new Date(endDate).getFullYear()}`;
  };

  useEffect(() => {
    const updateMarks = () => {
      if (window.innerWidth > 1023) {
        setCurrentMarks(fullMarks);
      } else {
        setCurrentMarks(marks);
      }
    }

    updateMarks();
    window.addEventListener('resize', updateMarks);

    return () => {
      window.removeEventListener('resize', updateMarks);
    }
  }, []);

  return (
      <div className="Home">
        <Header />

        <main>
          <section>
            <div className="main-container">
              <div className="background-image"></div>
              <div className="background-block"></div>
              <div className="swiper-container home-container">
                <div className="swiper-wrapper">
                  {featuredConferences.map((conference) => (
                      <div className="swiper-slide" key={conference.id}>
                        <div className="container slider-block-homepage">
                          <div className="breadcrumbs">Upcoming event</div>
                          <h2 className="title-main">{conference.eventName}</h2>
                          <p className="date-main">{formateDate(conference)}</p>
                          <Link
                              className="conference-link"
                              to={`/single-conference/${conference.slug}`}
                          >
                            <button className="button-main">
                              {" "}
                                Details
                            </button>
                          </Link>
                        </div>
                      </div>
                  ))}
                </div>
                <div className="arrow-container home-arrow-container">
                  <div className="left-arrow"></div>
                  <div className="right-arrow"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-about">
            <div className="background-about"></div>
            <h2 className="title-about">About us</h2>
            <div className="line-container">
              <span className="line-about"></span>
            </div>
            <div className="rectangle-container">
              <div className="rectangle-blue">
                <ul>
                  <li data-aos="fade-left" data-aos-delay="200">
                    Business Conference Facilitation is a professional organizer of high-level B2B events.
                    We produce, conduct and coordinate conferences, summits and forums in major business
                    industries of emerging Global Market.
                  </li>
                  <li data-aos="fade-left" data-aos-delay="400">
                    Our team craft each of our events to provide you with an environment aimed at benchmarking, networking, and generating insights amongst peers of various industries.
                  </li>
                  <li data-aos="fade-left" data-aos-delay="600">
                    Our committed team of experienced business professionals, with solid backgrounds in multidisciplinary fields, are here to create an exclusive environment at each of our tailor-made events.{" "}
                  </li>
                </ul>
              </div>
              <div className="rectangle-lightblue"></div>
            </div>
          </section>
          <section>
            <div className="container-conference">
              <div className="add-class-container">
                <h2 className="title-conference">About our conferences</h2>
                <div className="line-conference-container">
                  <span className="line-conference"></span>
                </div>
                <p className="conference-text">
                  Welcome to Business Conference Facilitation, where the industry&apos;s brightest minds in the realm of high-level B2B events come together to exchange insights and ideas.
                  <br />
                <br />
                  Our mission is straightforward: to unite the most exceptional professionals in the business, offering them a platform to connect, acquire knowledge, and develop together.
                  <br />
                  <br />
                  Our B2B Conferences section provides information about our upcoming events and how you can become a part of our thriving business community. We look forward to welcoming you!
                </p>
              </div>
              <div>
                <div className="rectangle-conference"></div>
                <div className="add-class-container"></div>
                <div className="rectangle-photos">
                  <ul className="list-conference-photo">
                    <li data-aos="fade-left" data-aos-delay="200">
                      <img
                          src={require("../../assets/images/1.jpg")}
                          alt=""
                      />
                    </li>
                    <li data-aos="fade-left" data-aos-delay="400">
                      <img
                          src={require("../../assets/images/2.jpg")}
                          alt=""
                      />
                    </li>
                    <li data-aos="fade-left" data-aos-delay="200">
                      <img
                          src={require("../../assets/images/3.jpg")}
                          alt=""
                      />
                    </li>
                    <li data-aos="fade-left" data-aos-delay="400">
                      <img
                          src={require("../../assets/images/5.jpg")}
                          alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="upcoming-section">
            <div>
              <div className="container">
                <h2 className="upcoming-title">Upcoming events</h2>
                <div className="line-container">
                  <span className="line-about"></span>
                </div>
              </div>
              <div className="slider-rc container upcoming-container">
                <Slider
                    marks={currentMarks}
                    min={1}
                    max={12}
                    onChange={(e) => changeSlider(e)}
                    step={1}
                    range
                    value={selectedMonth}
                    railStyle={{ backgroundColor: "white", height: "1px", bottom: "17px", padding: "0",  }}
                    handleStyle={handleStyle}
                    dotStyle={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      width: "0px",
                      height: "0px",
                      bottom: "5px",
                      zIndex: '2',
                    }}
                />
              </div>
              <div>
                <div
                    className="container special-container padding-list-ivents"
                    id="home-event-container"
                >
                  <div className="container-upcoming-events">
                  <div className="conference-events-wrapper">
                  {activeConferences?.map((conference) => (
                      <div className="col-class-upcoming" key={conference.id}>
                      <div
                          key={conference.id}
                          // className="content active"
                          data-month={new Date(conference.endDate).getMonth() + 1}
                      >
                        <div className="container-content" id="content-conferences">
                          <div className="data-content">
                            {" "}
                            {formateDate(conference)}
                          </div>
                          <div className="width-conferences">
                            <Link
                                className="conference-link"
                                to={`/single-conference/${conference.slug}`}
                            >
                              <h2 className="title-content">{conference.eventName}</h2>
                            </Link>
                            <div>
                              <span className="line-slider"></span>
                            </div>
                            <p className="description-content">
                              {conference.eventDescription}
                            </p>
                            <Link
                                className="conference-link"
                                to={`/single-conference/${conference.slug}`}
                            >
                                <button className="btn-content">Details</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      </div>
                  ))}
              </div>
              <div className="arrow-events">
                <div>
                  <Link className="conference-link" to="/conferences">
                    All events
                  </Link>
                </div>
                <Link to="/conferences">
                  {" "}
                  <div className="right-arrow"></div>
                </Link>
              </div>
            </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container container-special">
              <h2 className="title-about">Gallery</h2>
              <div className="line-container">
                <span className="line-about"></span>
              </div>
              <div className="parent">
                <div className="div1">
                  <img
                      className="image-parent1"
                      src={require("../../assets/images/gallery-second.jfif")}
                      alt=""
                  />
                </div>
                <div className="div2">
                  <img
                      className="image-parent2"
                      src={require("../../assets/images/gallery-third.jfif")}
                      alt=""
                  />
                </div>
                <div className="div3">
                  <img
                      className="image-parent3"
                      src={require("../../assets/images/gallery-first.jpg")}
                      alt=""
                  />
                </div>
                <div className="div4">
                  <img
                      className="image-parent4"
                      src={require("../../assets/images/gallery-fourth.jpg")}
                      alt=""
                  />
                </div>
                <div className="div5">
                  <img
                      className="image-parent5"
                      src={require("../../assets/images/gallery-fifth.jfif")}
                      alt=""
                  />
                </div>
                <div className="div6">
                  <img
                      className="image-parent6"
                      src={require("../../assets/images/gallery-sixth.jpg")}
                      alt=""
                  />
                </div>
                <div className="div7">
                  <img
                      className="image-parent7"
                      src={require("../../assets/images/gallery-seventh.jfif")}
                      alt=""
                  />
                </div>
              </div>
              <div className="more-container">
                <Link className="more-gallery" to="/gallery">
                  More
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
  );
}

export default Home;

import React, { useEffect } from 'react';

function StripePricingTable(props) {
    const { code, stripeId } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;

        script.onload = () => {
            const priceColumns = document.querySelectorAll('.PriceColumn');
            priceColumns.forEach((column) => {
                column.style.padding = '0 !important';
            });
        };

        document.body.appendChild(script);
    }, []);

    return (
        <div className="stripe-container">
            <stripe-pricing-table pricing-table-id={stripeId} publishable-key={code}>
            </stripe-pricing-table>
        </div>
    );
}

export default StripePricingTable;

import React, { useEffect } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import Breadcrumbs from "../../components/Breadcrumbs";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div className="breadcrumbs-about breadcrumbs-add">
              <ul>
                <li className="andThisStyle title-breadcrumbs">MAIN</li>
                <li className="thisStyle description-breadcrumbs">
                  DATA POLICY
                </li>
              </ul>
              <Breadcrumbs />
            </div>
            <h2 className="title-conferences title-contact-us event-contact-us">
              Data policy
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h3 className="title-terms">Introduction</h3>
            <p className="text-terms">
              This policy sets out how we collect, process and hold your data if
              you visit our website or otherwise provide personal data to us. We
              are bcf-events.com of Business Conference Facilitation. This
              policy affects your legal rights and obligations, so please read
              it carefully. If you have any questions about Information we
              collect, store and process, please contact us at
              general@bcf-events.com
            </p>
            <h3 className="title-terms">General Information</h3>
            <p className="text-terms">
              When you visit, interact or use our website, we collect general
              data and may as well collect personal data. General Data usually
              includes cookies that is recorded automatically when you first
              visit the website, to give you a better experience when you
              revisit our website.
            </p>
            <p className="text-terms">
              Personal Data include your name, your contact information and few
              other personal details as permitted by you. We collect, process,
              store and use personal data when you authorize us to see your
              personal details after you log in on our platform directly or
              through third party login system. We store your personal
              information for a limited time period when you submit registration
              to an event.
            </p>
            <p className="text-terms">
              You do not need to provide us with any personal information to
              view our website. However, we may still collect the information
              set under the cookie statement section of this policy, ‘Data we
              automatically collect’ section of this policy and marketing
              communications in accordance with the ‘Marketing Communications’
              section of this policy. When you contact us by email or post, we
              may keep a record of the correspondence, and we may also record
              any telephone call we have with you.
            </p>
            <h3 className="title-terms">Users</h3>
            <p className="text-terms">
              General information is automatically recorded from all the users.
              The data which is automatically collected includes the technical
              data that is sent to us from the computer, mobile device and
              browser through which users access our website or it’s services.
            </p>
            <p className="text-terms">
              Users action on our website, information on how users came to our
              website and data collected through Cookies, Pixel Code and other
              similar technologies are all included in the automatic general
              information collection.
            </p>
            <h3 className="title-terms">
              Consumers/Event Attendees/Subscribers
            </h3>
            <p className="text-terms">
              If you are a consumer or an event attendee or someone who uses
              bcf-events.com, we collect additional data from your end such as
              your name and contact information. This additional data is mostly
              used for our own purposes. However, when you register for an event
              through bcf-events.com, we collect that data, and we will only use
              the data to invite you to our future events.
            </p>
            <p className="text-terms">
              If you other emails apart from invitation to any of our future
              events. You can at any point unsubscribe directly from such mails
              or contact us directly to delete your data. If you contact us, we
              may remove or update your information within a reasonable time.
            </p>
            <h3 className="title-terms">Privacy Policy</h3>
            <p className="text-terms">
              This privacy policy explains how BCF Group (hereinafter referred to as “Company”), with its registered office in Prague, collects and processes personal data obtained through the internet website on which this policy is found, in compliance with the General Data Protection Regulation (GDPR). By using this website, you acknowledge that you have read, understood, and agree to the terms of this privacy policy. If you do not agree with our privacy policy, please do not use this website.
            </p>
            <h3 className="title-terms">
              Personal Data Collected
            </h3>
            <p className="text-terms">
              Protecting the privacy of our users’ personal data is a top priority. As part of our commitment to complying with the GDPR, we provide this statement on how we use your personal data. <br /><br />
              We only collect personal data that is necessary to provide our services and manage your use of the website. We collect personal data directly from you when submitted, or through other means like cookies, as described in our cookie policy.
            </p>

              <p className="text-terms">
                  We collect your personal data through the following means: <br /><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • Registration and submission of information that you voluntarily enter on certain pages and forms on the Website.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Establishment of a contractual relationship with the Company, where you provide your personal data for the purpose of upholding the agreement.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • Usage of automated means, specifically through cookies. For further details regarding cookies, kindly refer to this <a href="/terms">link.</a>.
  </span><br />
              </p>

              <h3 className="title-terms">
                  Personal Data Categories
              </h3>
              <p className="text-terms">
                  When fulfilling the role of a data controller, we abide by the principles set forth in the General Data Protection Regulation (GDPR), and take the processing of personal data seriously. In the course of our provision of services, we typically collect various categories of personal data, including but not limited to: <br /><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Identification data, such as your name, surname, details of your employer, corporate phone number, and job position.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Contact information, such as your email address.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Data related to the contracted service, such as the service provided and any associated fees.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Bank card details, where you choose to pay for our service online.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • Any other data that you provide to us or that we discover during the provision of our service, including data created in relation to our obligations based on the data provided.
  </span><br />
              </p>


              <h3 className="title-terms">
              Personal Data Usage
            </h3>
              <p className="text-terms">
                  We ensure that all personal data collected is processed in a manner that is consistent with the GDPR and is necessary for the provision of our services. <br /><br />
                  We use personal data for the following purposes: <br /><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • To respond to your direct requests, as described at the time when we collect your data or as otherwise described to you prior to the use of your data.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • To manage your use of our website, including providing access to your account, processing your transactions, and providing customer support.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'pre-wrap' }}>
    • We also use personal data explicitly for marketing purposes. This involves tailoring our communications to your preferences, sending you relevant updates, promotions, and offers, and ensuring you are informed about our latest events.
  </span><br /><br />
                  For other legitimate purposes, such as fulfilling contractual obligations towards you or your employer, conducting internal research and analytics, and improving our services.
              </p>


              <h3 className="title-terms">
              Data Retention
            </h3>
            <p className="text-terms">
              In accordance with the GDPR, we process and retain personal data for the duration necessary to fulfill the rights and obligations outlined in the contractual agreement, and for any additional duration mandated by applicable laws and regulations. For marketing purposes, we retain personal data for up to four (4) years following the expiration of the contractual relationship. In all other cases, the retention period is determined by the purpose of the data processing or as stipulated by relevant legislation or termination date (e.g. as of consent withdrawal or contract termination terms).
            </p>

            <h3 className="title-terms">
              Sensitive Information
            </h3>
              <p className="text-terms">
                  Sensitive information is any personal data that relates to an individual’s racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, health data, sex life or sexual orientation. <br /><br />
                  We are committed to the following: <br /><br />
                  Protecting the privacy and security of the personal data of our users. This policy outlines our approach to managing sensitive information and our commitment to complying with the EU General Data Protection Regulation (GDPR). <br /><br />
                  Only collect sensitive information from you with your explicit consent or where we are required to do so by law. We may collect sensitive information from you when you: <br /><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Sign up for our services that require such data.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Contact us with inquiries or requests.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Participate in surveys or competitions.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • Submit content, feedback or comments.
  </span><br /><br />
                  Only use your sensitive information for the specific purpose for which it was collected or as otherwise permitted by the GDPR. We will not use your sensitive information for any other purpose without your express consent. <br /><br />
                  Protect your sensitive information by using reasonable measures to ensure the security of your sensitive information, including using encryption and secure storage systems, and limiting access to those who need it. We will retain your sensitive information for as long as necessary to fulfill the purpose for which it was collected, or as required by law.
              </p>


              <h3 className="title-terms">
                  Your Rights Under the GDPR
              </h3>
              <p className="text-terms">
                  You have certain rights under the GDPR in relation to your sensitive information. These include: <br /><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to access your sensitive information.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to rectify any inaccuracies in your sensitive information.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to erasure of your sensitive information in certain circumstances.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to restrict the processing of your sensitive information.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to object to the processing of your sensitive information.
  </span><br />
                  <span style={{ display: 'block', paddingLeft: '0.5em', whiteSpace: 'nowrap' }}>
    • The right to data portability of your sensitive information.
  </span><br /><br />
                  We will respond to any requests you make to exercise your GDPR rights in a timely and appropriate manner.
              </p>


              <h3 className="title-terms">
              Disclosure of Your Sensitive Information
            </h3>
            <p className="text-terms">
              We may disclose your sensitive information to our employees, agents, or service providers who need it to carry out their duties. We will only disclose your sensitive information to third parties with your consent or where we are required to do so by law.
            </p>

              <h3 className="title-terms">
                  Sharing personal data and transferring personal data to other jurisdictions
              </h3>
              <p className="text-terms">
                  <strong>
                      NOTE: this still requires a review/check and a rewrite
                  </strong> <br /><br />
                  Please note that in certain cases, your personal data may be transferred to Hotels in order to provide accommodation service to you whenever the nature of the event is not remote/online, or where the event is held online to third parties who provide online platform to enable participation in the event. By completing and submitting your on-line application you consent to the transfer of your personal data in such cases. <br /><br />
                  Please note that subject to applicable data privacy legislation, we will also disclose your personal data if we believe we are required to do so to comply with any law, regulation, court order, or legal or government request. In the unlikely event that all or part of our business is acquired by a third party, your data will be transferred to the new corporate owner.
              </p>




              <h3 className="title-terms">
              Security
            </h3>
            <p className="text-terms">
              Protecting the privacy and security of our users’ personal data is of utmost importance to us. As part of our commitment to complying with the EU General Data Protection Regulation (GDPR), we provide this statement on data security. <br /><br />
              <strong>Security measures:</strong> We have implemented a range of technical and organizational measures to ensure the security of our data and protect against unauthorized access, loss, or theft of personal data. These measures include, but are not limited to, firewalls, encryption, access controls, and regular security audits. <br /><br />
              <strong>Third-party services:</strong> We use third-party services to support our website, such as hosting and data storage providers. We ensure that these services also comply with the GDPR and implement appropriate security measures to protect personal data. <br /><br />
              <strong>Data breaches:</strong> In the unlikely event of a data breach, we will take immediate action to minimize any potential harm and comply with our obligations under the GDPR. We will notify affected users and the relevant supervisory authority within 72 hours of becoming aware of the breach, and provide details of the type of data affected, the likely consequences, and the measures we have taken to address the breach.
            </p>
              <p className="text-terms">
                  We shall process your data in a manner that ensures appropriate
                  security of the data, including protection against unauthorized or
                  unlawful processing and against accidental loss, destruction or
                  damage, using appropriate technical or organizational measures.
                  All information you provide to us is stored on our secure servers.
                  Any payment transactions are encrypted using SSL technology. Where
                  we have given, or you have chosen a password, you are responsible
                  for keeping this password confidential. However, you acknowledge
                  that no system can be completely secure. Therefore, although we
                  take these steps to secure your data, we do not promise that your
                  data will always remain completely secure.
              </p>

            <h3 className="title-terms">
              Lodging a Complaint
            </h3>
            <p className="text-terms">
              At Future Bridge, we are committed to protecting the privacy and security of our users’ personal data. If you believe that we have not complied with the EU General Data Protection Regulation (GDPR), you have the right to lodge a complaint with a supervisory authority. <br /><br />
              <strong>How to lodge a complaint:</strong> If you wish to lodge a complaint with a supervisory authority, you should contact the Data Protection Authority (DPA) in your country of residence. The DPA will investigate your complaint and provide you with a response. You can find contact details for your local DPA on the European Commission’s website: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-protection-eu_en" target="_blank" rel="noreferrer">https://ec.europa.eu/info/law/law-topic/data-protection/data-protection-eu_en</a>. <br /><br />
              If you are not satisfied with the DPA’s response, you may have the right to seek a judicial remedy. You should seek legal advice if you wish to pursue this option.
            </p>

            <h3 className="title-terms">
              How We Will Handle Complaints
            </h3>
            <p className="text-terms">
              We take all complaints seriously and will cooperate with the DPA in any investigation. We will respond to any requests for information from the DPA in a timely and appropriate manner. <br /><br />
              We will also take steps to prevent similar complaints from arising in the future by reviewing our practices and making any necessary changes to ensure compliance with the GDPR.
            </p>

            <h3 className="title-terms">
              Contact Us
            </h3>
            <p className="text-terms">
              If you have any questions or concerns about the security of your personal data on our website, please contact us using the contact details provided on our website. We will respond to your inquiry promptly and provide you with any information or assistance you may need. <br /><br />
              Email: <a href="mailto:general@bcf-events.com">general@bcf-events.com</a>
            </p>
            <h3 className="title-terms">
              Updates to this Policy
            </h3>
            <p className="text-terms">
              We may update this policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically to stay informed about our approach to managing sensitive information.
            </p>

            <h3 className="title-terms">Retention</h3>
            <p className="text-terms">
              If you receive communication from us, we shall retain your data
              until you opt out of receiving such communications. If you
              register with us, we shall retain your data until you close your
              account. You can delete your data or your account by contacting us
              on general@bcf-events.com However, we may hold your data for an
              extra period as is allowed or required under applicable laws. Your
              data after complete deletion might still be available on backup
              for even a longer period of time for reasons persistence to legal,
              tax and lawful business purposes. If you have otherwise booked a
              ticket with us or contacted us with a question or comment, we
              shall retain your personal information for at least 6 months
              following such contact to respond to any further queries you might
              have. When an individual books a ticket for an event, their
              personal data like name, email address and other registration
              information for coordinating check-ins during the event. If by any
              means.
            </p>
            <h3 className="title-terms">Cookies</h3>
            <p className="text-terms">
              Cookie is a small text file containing a unique identification
              number that is transferred (through your browser) from a website
              to the hard drive of your computer. The cookie identifies your
              browser but will not let a website know any personal data about
              you, such as your name and/or address. These files are then used
              by websites to identify when users revisit that website. Our
              website uses cookies so that we can recognize you when you return
              and personalize your settings and preferences. Most browsers are
              initially set up to accept cookies. You can change your browser
              settings either to notify you when you have received a cookie, or
              to refuse to accept cookies.
            </p>
            <p className="text-terms">
              Please note that we may not be able to remember your choices or
              operate efficiently or give you personalised experience if you
              refuse to accept cookies. We also use Google Analytics to monitor
              how our website is used. Google Analytics collects information
              anonymously and generates reports detailing information such as
              the number of visits to the event pages, where visitors generally
              came from, how long they stayed on the event pages, and which
              event pages they visited. Google Analytics places several
              persistent cookies on your computer’s hard drive. These do not
              collect any personal data. If you do not agree to this you can
              disable persistent cookies in your browser.
            </p>
            <p className="text-terms">
              This will prevent Google Analytics from logging your visits. We
              also use Social Networking Cookies, analytics or tracking pixels
              on our website, which remembers your interest for a particular
              event or piece of information available on our and partner
              websites. This is done to show you relevant ads on the social
              networking sites. For more information about the pixel tracking
              you can check out Facebook Data Policy and Smart look. To support
              our business we also allow third party advertisements on our
              website. Some of these advertisers may use technology such as
              cookies and web beacons when they advertise on our website, which
              will also send these advertisers your automatically collected
              information such as IP address, your ISP, the browser you used to
              visit our site when you interact or even see their advertisement
              on our site. For more details regarding how we third party
              vendors, including google manages data in its ads products, please
              check the partner-sites policy as provided by Google.
            </p>
            <h3 className="title-terms">Your rights</h3>
            <p className="text-terms">
              You have the right to obtain from us a copy of the personal data
              that we hold for you, and to require us to correct errors in that
              data if it is inaccurate or incomplete. You also have the right at
              any time to require that we delete your personal data, object to
              profiling and unsubscribe from marketing communications. To
              exercise these rights, or any other rights you may have under
              applicable laws, please contact us at general@bcf-events.com.
              Please note, we reserve the right to charge an administrative fee
              if your request is manifestly unfounded or excessive. If you have
              any complaints in relation to this policy or otherwise in relation
              to our processing of your data, please get in touch with us at
              general@bcf-events.com, so we can resolve your complaint. If you
              are not satisfied with our attempt to resolve your privacy related
              complaints, you may contact the relevant data protection
              authority.
            </p>
            <p className="text-terms">
              Our website pages may contain links to other sites of interest.
              Once you have used these links to leave our platform, you should
              note that we do not have any control over that other site.
              Therefore, we cannot be responsible for the protection and privacy
              of any information which you provide whilst visiting such sites
              and such sites are not governed by this policy. You should
              exercise caution and look at the privacy policy applicable to the
              site in question.
            </p>
            <h3 className="title-terms">API & Plugins Use</h3>
            <p className="text-terms">
              Bcf-events.com aggregates public events from multiple third party
              providers from their APIs. We take request from APIs only for the
              public events and not private events. In cases, where a user or an
              event organizer or any individual might want to report an event,
              they can do so through the event page or by contacting us at
              general@bcf-events.com We also provide APIs and Event Plugins to
              third party or partner websites which implies that when an
              individual creates an event on bcf-events.com, it may be used by
              third party or partner websites to display your events for a
              greater reach.
            </p>
            <h3 className="title-terms">
              Facebook, Google and Other Third Party Integrations
            </h3>
            <p className="text-terms">
              All the third party integrations and the company or organization
              names mentioned in this policy have their own copyright/word mark,
              trademark, terms, security and privacy policy which can be found
              on their own website.
            </p>
            <h3 className="title-terms">Changes of this privacy statement</h3>
            <p className="text-terms last-terms">
              We may change the terms of this policy from time to time. You are
              responsible for regularly reviewing this policy so that you are
              aware of any changes to it. If you continue to use our website
              after the time we state the changes will take effect, you will
              have accepted the changes.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Privacy;

import React, { useEffect } from "react";

const Breadcrumbs = (props) => {
    const { conferenceName } = props;

    useEffect(() => {
        const elements = document.querySelectorAll('.andThisStyle, .thisStyle');
        const lineElement = document.querySelector('.line-breadcrumbs');

        if (elements.length && lineElement) {
            let totalWidth = Array.from(elements).reduce((acc, el) => acc + el.getBoundingClientRect().width, 0);
            lineElement.style.width = totalWidth + 'px';

            let firstElementWidth = elements[0].getBoundingClientRect().width;
            let style = document.createElement('style');
            style.innerHTML = `
                .line-breadcrumbs:after {
                    width: ${firstElementWidth}px !important;
                }
            `;
            document.head.appendChild(style);
        }
    }, [conferenceName]);  // Добавьте conferenceName как зависимость

    return (
        <div className="breadcrumbs-wrapper">
            <span className="line-breadcrumbs"></span>
        </div>
    );
}

export default Breadcrumbs;
import React from 'react';
import { useState, useCallback } from 'react';
import AuthContext from "./context/AuthContext";
import allowedEmails from "./login/allowedEmails";
import { NotificationManager } from "react-notifications";

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [ setSpeakers] = useState([]);

    const updateSpeakers = useCallback((speakersList) => {
        setSpeakers(speakersList);
    }, [setSpeakers]); // ESLint больше не будет выдавать предупреждение


    const login = ({ email, conferenceName }) => {
        // Приводим email к нижнему регистру для нечувствительности к регистру
        const lowerCaseEmail = email.toLowerCase();
        const isAllowedEmail = allowedEmails.some(entry =>
            entry.email.toLowerCase() === lowerCaseEmail && entry.eventName === conferenceName
        );

        if (isAllowedEmail) {
            setAuth({ user: email }); // Вы можете сохранить оригинальный email или также привести его к нижнему регистру
        } else {
            NotificationManager.error('You don\'t have access to the post material\n', 'Unfortunately', 5000);
        }
    };

    return (
        <AuthContext.Provider value={{ auth, login, updateSpeakers }}>
            {children}
        </AuthContext.Provider>
    );
};

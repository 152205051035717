import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.png';

function HeaderConferences() {
    const [isOpened, setIsOpened] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const menuButton = document.querySelector('.menu');
        const mobileNav = document.createElement('div');
        mobileNav.classList.add('mobile-nav', 'mobile-menu');
        mobileNav.innerHTML = `
          <ul class="list-navigation">
            <a href="/"><li class="mobile-list">HOME</li></a>
            <a href="/about"><li class="mobile-list">ABOUT US</li></a>
            <a href="/events"><li class="mobile-list">PAST EVENTS</li></a>
            <a href="/sponsorship"><li class="mobile-list">SPONSORSHIP</li></a>
            <a href='/conferences'><li class="mobile-list">B2B CONFERENCES</li></a>
            <a href="/gallery"><li class="mobile-list">GALLERY</li></a>
            <a href="/contacts"><li class="mobile-list">CONTACT US</li></a>
            <span class="line-burger"></span>
            <div class="social-mobile">
              <a href="#"><img src="https://i.ibb.co/2s3CrMg/telegram-mobile.png"/></a>
              <a href="https://www.instagram.com/bcfgroupeu/"><img src="https://i.ibb.co/d2RWsz8/instagram-mobile.png"/></a>
              <a href="https://twitter.com/BCFgroupeu"><img src="https://i.ibb.co/9Wcv40d/twitter-mobile.png"/></a>
              <a href="#"><img src="https://i.ibb.co/DtCYbB8/google-mobile.png"/></a>
              <a href="https://www.linkedin.com/company/business-conference-facilitation/"><img src="https://i.ibb.co/By4k6qH/linkedin-mobile.png"/></a>
            </div>
          </ul>
        `;
        document.body.appendChild(mobileNav);

        const toggleMobileMenu = () => {
            const mobileMenu = document.querySelector('.mobile-nav');
            mobileMenu.classList.toggle('open');
        };

        menuButton.addEventListener('click', toggleMobileMenu);

        return () => {
            menuButton.removeEventListener('click', toggleMobileMenu);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
                document.querySelector('.desktop-header-conferences').style.paddingTop = '0';
            } else {
                setIsScrolled(false);
                document.querySelector('.desktop-header-conferences').style.paddingTop = '50px';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpened(prevState => !prevState);
    };

    return (
        <header className="header-conferences">
            <div className="desktop-header-conferences">
                <a href='/'><img src={logo} alt="Logo" /></a>
                <nav>
                    <ul>
                        <a href="/about"><li>ABOUT US</li></a>
                        <a href='/sponsorship'><li>SPONSORSHIP</li></a>
                        <a href='/conferences'><li>B2B CONFERENCES</li></a>
                        <a href="/gallery"><li>GALLERY</li></a>
                        <a href='/events'><li>PAST EVENTS</li></a>
                        <a href='/contacts'><li>CONTACT US</li></a>
                    </ul>
                </nav>
            </div>
            <div className="mobile-menu">
                <div className="mobile-header mobile-conferences">
                    <div className="flex justify-between items-center mobile-class">
                        <div className="block-output">
                            <a href='/'>
                                <img
                                    src={logo}
                                    style={{ width: '71px', height: '26px' }}
                                    className="logo-laptop"
                                    alt="Logo"
                                />
                            </a>
                        </div>
                        <div className="burger-class">
                            <button
                                className={`menu ${isOpened ? 'opened' : ''}`}
                                onClick={toggleMenu}
                                aria-expanded={isOpened}
                                aria-label="Main Menu"
                            >
                                <svg width="50" height="50" className="svg-mobile-hamburger" viewBox="0 0 100 100">
                                    <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                                    <path className="line line2" d="M 20,50 H 80" />
                                    <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderConferences;
import React, { useEffect, useState } from 'react';
import Footer from '../../components/website/Footer';
import HeaderConferences from '../../components/website/HeaderConferences';
import Breadcrumbs from "../../components/Breadcrumbs";
import StripePricingTable from "../../components/StripePricingTable";
import { useParams } from "react-router-dom";
import api from "../../api/endpoints/events";

function Stripe() {
    const { id } = useParams();
    const [stripeData, setStripeData] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await api.get(`/Event/get-stripe-data/${id}`);
                setStripeData(data);
                console.log('Conference data: ', data);
            } catch (error) {
                console.log('Error fetching data: ', error);
            }
        };

        fetchData();
    }, [id]); // Добавляем 'id' в зависимости


    // Render the component only when stripeData is available
    if (!stripeData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <HeaderConferences />
            <main className="stripe-container">
                <section>
                    <div className="background-block unsubscribe-block">
                        <div className="container">
                            <div className="breadcrumbs-about">
                                <ul>
                                    <a href="/">
                                        <li className="andThisStyle title-breadcrumbs unsubscribe-breadcrumbs">MAIN</li>
                                    </a>
                                    <a href="/stripe">
                                        <li className="thisStyle description-breadcrumbs unsubscribe-breadcrumbs">
                                            STRIPE
                                        </li>
                                    </a>
                                </ul>
                                <Breadcrumbs />
                            </div>
                            <div className="background-stripe"></div>
                            <div className="stripe-block">
                                <h2 className="unsubscribe-title">{stripeData.eventName}</h2>
                            </div>
                            <StripePricingTable code={stripeData.stripeCode} stripeId={stripeData.stripeId}/>
                            <div className="opacity">Hello, This is Stripe Payment Page!</div>
                        </div>
                    </div>
                </section>
                {stripeData.stripeEmployee && (
                <div className="container container-stripe">
                  <h2 className="title-about title-contact-us-stripe">FOR BOOKING ASSISTANCE PLEASE CONTACT</h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  <div>
                    <ul className="contact-list contact-list-stripe">
                        <li key={stripeData.stripeEmployee.id}>
                            <img width="150px" src={stripeData.stripeEmployee.photo} alt="" />
                            <h3>{stripeData.stripeEmployee.fullName}</h3>
                            <h4>{stripeData.stripeEmployee.position}</h4>
                            <p className="phone">{stripeData.stripeEmployee.phone}</p>
                            <p className="email">{stripeData.stripeEmployee.email}</p>
                        </li>
                    </ul>
                  </div>
                </div>
                )}
            </main>
            <Footer />
        </div>
    );
}

export default Stripe;

import React, { useEffect, useState } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import api from "../../api/endpoints/events.js";
import Loader from "react-spinners/ClipLoader";
import notAvailableImage from "../../assets/images/not-available.jpeg";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

function formatDate(dateString) {
  if (!dateString) {
    return ''; // Return an empty string or another appropriate value for null dates
  }
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}
function Gallery() {
  const [allConferences, setAllConferences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [galleryConferences, setGalleryConferences] = useState([]);

  const getAllConferences = async () => {
    const { data } = await api.get(
        "/Gallery/get-all-galleries?pageNumber=1&pageSize=200"
    );
    try {
      setAllConferences(data.items);
      console.log("data", data);
    } catch (err) {
      console.log(err, "error");
    }
    setIsLoading(false); // Stop loading
  };

  const transformedItems = () => {
    let result = allConferences.reduce((result, item) => {
      const year = new Date(item.eventDate).getFullYear();
      const month = new Date(item.eventDate).getMonth();
      const index = result.findIndex((obj) => obj.year == year);

      if (index !== -1) {
        result[index].items.push({ month, event: item });
      } else {
        result.push({ year, items: [{ month, event: item }] });
      }

      return result;
    }, []);

    result.forEach((yearObj) => {
      yearObj.items.sort((a, b) => b.month - a.month);
    });

    result.sort((a, b) => {
      // Сначала по году в убывающем порядке
      if (a.year !== b.year) {
        return b.year - a.year;
      }

      // Затем по месяцу в убывающем порядке
      return b.items[0].month - a.items[0].month;
    });

    // Убираем месяц из структуры данных, оставляем только события
    result.forEach((yearObj) => {
      yearObj.items = yearObj.items.map((eventObj) => eventObj.event);
    });

    setGalleryConferences(result);
  };


  useEffect(() => {
    getAllConferences();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    transformedItems();
  }, [allConferences]);

  console.log("galleryConferences", galleryConferences);
  return (
      <div>
        <HeaderConferences />
        <main>
          <section>
            <div className="container">
              <div className="breadcrumbs-about breadcrumbs-add">
                <ul>
                  <li className="andThisStyle title-breadcrumbs">
                    <Link to="/">MAIN</Link>
                  </li>
                  <li className="thisStyle description-breadcrumbs second-bread">
                    <Link to="/events"> PAST EVENTS</Link>
                  </li>
                  <li className="thisStyle description-breadcrumbs post-description">
                    PHOTOGALLERY
                  </li>
                </ul>
                <Breadcrumbs />
              </div>
              <h2 className="title-conferences title-contact-us event-contact-us">
                Photogallery
              </h2>
              <div className="line-conferences line-contact-us line-event-us">
                <span className="line-about"></span>
              </div>
            </div>
          </section>
          <section>
            {isLoading ? (
                <div className="loader-container">
                  <Loader size={50} color={"#123abc"} loading={isLoading} />
                </div>
            ) : (
                <div className="container">
                  <div className="photo_container">
                    {galleryConferences.map((item) => (
                        <React.Fragment key={item.year}>
                          {item.items.map((gallery) => (
                              <div
                                  key={gallery.id}
                                  className="online-item event-item-add"
                              >
                                <img
                                    src={`${
                                        gallery.imageCover
                                            ? gallery.imageCover
                                            : notAvailableImage
                                    }`}
                                    alt=""
                                />
                                <h2 className="online-title">{gallery.galleryName}</h2>
                                <div className="date-container">
                                  <button className="online-btn">
                                    <Link
                                        className="conference-link"
                                        to={`/single-gallery/${gallery.id}`}
                                    >
                                      {" "}
                                      Read more
                                    </Link>
                                  </button>
                                  <p className="date-text">{formatDate(gallery.eventDate)}</p>
                                </div>
                              </div>
                          ))}

                        </React.Fragment>
                    ))}
                  </div>
                </div>
            )}
          </section>
        </main>
        <Footer />
      </div>
  );
}

export default Gallery;
import React, { useEffect } from "react";

function JivositeWidget() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//code.jivosite.com/widget/5ODGG1tya9";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div />;
}

export default JivositeWidget
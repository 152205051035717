import React, { useEffect } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

function Events() {
  const history = useHistory();

  const handleClick = () => {
    history.push("/online");
  };
  const handleClickOffline = () => {
    history.push("/offline");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   let first = document.querySelector('.andThisStyle');
  //   let two = document.querySelector('.thisStyle');
  //   let lineElement = document.querySelector('.line-breadcrumbs');
  //
  //   let styleWidth = first.getBoundingClientRect().width;
  //   let styleWidthTwo = two.getBoundingClientRect().width;
  //   lineElement.style.width = (styleWidth + styleWidthTwo) + 'px';
  //   let afterWidth = styleWidth;
  //
  //   let style = document.createElement('style');
  //   style.innerHTML = `
  //     .line-breadcrumbs:after {
  //         width: ${afterWidth}px !important;
  //     }
  //   `;
  //   document.head.appendChild(style);
  // }, []);

  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div className="breadcrumbs-about breadcrumbs-add">
              <ul>
                <a href="/">
                  <li className="andThisStyle title-breadcrumbs">MAIN</li>
                </a>
                <a href="/events">
                  <li className="thisStyle description-breadcrumbs">
                    PAST EVENTS
                  </li>
                </a>
              </ul>
            </div>
            <Breadcrumbs />
            <h2 className="title-conferences title-contact-us event-contact-us">
              Past events
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="past-events-wrapper">
              <div className="event-item">
                <img
                  src={require("../../assets/images/past-events-first.png")}
                  alt=""
                />
                <h2 className="event-title">Offline Events</h2>
                <button className="event-btn" onClick={handleClickOffline}>
                  Read more
                </button>
              </div>
              <div className="event-item event-item-add">
                <img
                    src={require("../../assets/images/past-events-second.png")}
                    alt=""
                />
                <h2 className="event-title">ONLINE EVENTS</h2>
                <button className="event-btn" onClick={handleClick}>
                  Read more
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Events;

const getPartnerUrl = (partner, conferenceName) => {
    // Проверяем данные из базы данных
    // console.log("Данные из базы данных (partner.urlPartnerLogo):", partner.urlPartnerLogo);
    // Получаем уникальный идентификатор партнера (предположим, что у вас есть поле partnerName)
    const partnerName = partner.partnerName;
    const partnerUrls = partner.urlPartnerLogo;

    const conferenceUrls = {
        "Industry Events": {
            "INTERNATIONAL HRD SUMMIT": "events/international-hrd-summit",
            "5TH ANNUAL BTEL SUMMIT": "events/5-annual-biocompatibility-toxicology-and-e-l-summit",
            "2ND ANNUAL GENOTOXICITY AND NITROSAMINE ONLINE CONFERENCE": "events/2nd-annual-genotoxicity-and-nitrosamine-summit",
            "THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION CONFERENCE": "6th-annual-sustainable-cleaning-products-formulation-conference",
            // Добавьте URL для конкретных конференций партнёра "Industry Events"
        },
        // Добавьте других партнёров и их конференции
    };

    const partnerUrlPart = partnerUrls || '';
    const conferenceUrlPart = conferenceUrls[partnerName]?.[conferenceName] || '';

    const finalUrl = partnerUrlPart + conferenceUrlPart;

    // console.log("Полученный конечный URL:", finalUrl);

    return finalUrl;
};

export default getPartnerUrl;
// src/firebase.js
import { initializeApp } from "firebase/app";
import { getStorage, ref as storageRef } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyANAsZHMA56b0ihm1Kyyfe4XgvnstZjlio",
  authDomain: "sns-cz.firebaseapp.com",
  databaseURL: "https://sns-cz.firebaseio.com",
  projectId: "sns-cz",
  storageBucket: "sns-cz.appspot.com",
  messagingSenderId: "394833132240",
  appId: "1:394833132240:web:6470fe966eb9af6dfefcd5",
  measurementId: "G-7NHTH809D6",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage, storageRef };
export default storage;

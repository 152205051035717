import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectToBTEL = () => {
    // Здесь вы можете выполнить какую-то логику перед редиректом (если нужно)

    // После логики редиректим пользователя
    return <Redirect to="/single-conference/5th-annual-btel-summit" />;
};
// ещё раз

export default RedirectToBTEL;

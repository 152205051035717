import axios from "../axiosInstance";

let baseUrl = '';
if (window.location.hostname === 'localhost') {
  baseUrl = 'https://api.bcf-events.com';
}
if (
    window.location.hostname === 'green-desert-021865b10.3.azurestaticapps.net' ||
    window.location.hostname === 'brave-dune-00ae5aa10.3.azurestaticapps.net' ||
    window.location.hostname === 'new.bcf-events.com' ||
    window.location.hostname === 'bcf-events.com'
) {
  baseUrl = 'https://api.bcf-events.com';
}

export const getEvents = (pageSize, pageNumber) => {
  // Define the query parameters as an object
  const params = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 50
  };

  // Check and append slash if necessary
  const url = baseUrl.endsWith('/') ? baseUrl + 'Event/get-all-events' : baseUrl + '/Event/get-all-events';

  return axios.get(url, {
    params: params,
  })
      .catch((error) => {
        console.error("Error retrieving event data:", error);
        throw error;
      });
};

const api = axios.create({
  baseURL: baseUrl
});

export default api;

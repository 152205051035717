// src/api.js
export const fetchData = async () => {
    const baseUrl = 'https://script.google.com/macros/s/AKfycbzldsTsFs2IMkdROIOkLX58YkFVbfvdqz0--wQtF3CGOc6oJAEkQYyi4ytDcd9Z93NF/exec';
    const url = `${baseUrl}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Сетевая ошибка при получении данных');
        }
        const data = await response.json();
        if (data.status === "success") {
            return data.data;
        } else {
            throw new Error(data.message || 'Ошибка при получении данных');
        }
    } catch (error) {
        console.error('Ошибка при получении данных:', error);
        throw error;
    }
};
